<template>
  <a
    @click="toggle"
    class="csn-nav-menu-dropdown csn-nav-btn csn-notification-button casino-btn-default"
  >
    <span class="glyphicon glyphicon-bell"></span>
    <span v-if="hasNotifications" class="csn-notification-red-cirle">
      {{ listLength }}
    </span>
  </a>
</template>
<script>
import { USER_NOTIFICATION_BUTTON, EventType } from '@/constants'

export default {
  name: USER_NOTIFICATION_BUTTON,
  props: {
    hasNotifications: Boolean,
    listLength: Number,
  },
  methods: {
    toggle() {
      this.$emit(EventType.TOGGLE)
    },
  },
}
</script>
